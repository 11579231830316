import { Image } from './Image';
import { Link } from './Link';

import { ComponentData } from './index';

export type Card = {
  title: string;
  image: Image;
  link: Link;
};

export type OnwardJourneyData = {
  cards: Array<Card>;
  title: Nullable<string>;
  type: 'onwardJourney';
};

export function isOnwardJourney(data: ComponentData): data is OnwardJourneyData {
  return data.type === 'onwardJourney';
}
