import type { UseSponsoredProductsResponse } from '../contracts/SponsoredProducts';

export type criteoEventType = 'onLoad' | 'onView' | 'onClick';

export default async function criteoEventTracker(
  eventType: criteoEventType,
  criteoApiInfo: UseSponsoredProductsResponse,
  isBannerEnabled: boolean,
): Promise<void> {
  const { sponsoredProducts, banner } = criteoApiInfo;

  if (sponsoredProducts && sponsoredProducts.length) {
    const [{ OnLoadBeacon, products }] = sponsoredProducts;
    const eventUrls: Array<string> = [];

    if (eventType === 'onLoad') {
      eventUrls.push(OnLoadBeacon, ...products.map(product => product.OnLoadBeacon));
    }
    try {
      await Promise.all(eventUrls.map(beaconUrl => fetch(`https:${beaconUrl}`)));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  if (banner && banner.length && isBannerEnabled) {
    const [{ OnLoadBeacon }] = banner;

    try {
      await fetch(`https:${OnLoadBeacon}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
