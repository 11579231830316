import { ComponentData } from './index';

export type SearchType = 'searchTerm' | 'categoryPath';

export type ProductCarouselData = {
  title: Nullable<string>;
  seoTitle: Nullable<string>;
  searchType: SearchType;
  searchTerm: string;
  maxItems: number;
  linkText: Nullable<string>;
  type: 'productCarousel';
};

export function isProductCarousel(data: ComponentData): data is ProductCarouselData {
  return data.type === 'productCarousel';
}
