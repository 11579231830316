import { env } from '@selfridges-pkg/utils';

import { FetchCriteoListApiProps } from './contracts/SponsoredProducts';
import criteoEventTracker from './event-tracker';

export async function fetchCriteoList({
  criteoPageId,
  visitor_status,
  wcs_user_id,
  visitor_id,
}: FetchCriteoListApiProps) {
  const CRITEO_API_HOST = () => env('REACT_APP_CRITEO_API_HOST') || '';
  const CRITEO_PARTNER_ID = () => env('REACT_APP_CRITEO_PARTNER_ID') || '';
  const userLoggedIn = visitor_status && visitor_status !== 'guest' && visitor_status !== 'logged out';
  let CRITEO_URL = `${CRITEO_API_HOST()}/delivery/retailmedia?criteo-partner-id=${CRITEO_PARTNER_ID()}&page-id=${criteoPageId}&event-type=viewHome&retailer-visitor-id=${visitor_id}`;

  if (userLoggedIn) {
    CRITEO_URL = `${CRITEO_URL}&customer-id=${wcs_user_id}`;
  }
  const response = await fetch(CRITEO_URL);

  return await response.json();
}

export { criteoEventTracker };
