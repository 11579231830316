import { Link } from './Link';
import { Image } from './Image';

import { ComponentData } from './index';

export type PromotionalHeaderData = {
  type: 'promotionalHeader';
  backgroundColour: string;
  image: Image;
  primaryLink: Link;
  links: Nullable<Array<Link>>;
};

export function isPromotionalHeader(data: ComponentData): data is PromotionalHeaderData {
  return data.type === 'promotionalHeader';
}
