import { Image } from './Image';

import { ComponentData } from './index';

export type Card = {
  title: string;
  image: Image;
};

export type CarouselData = {
  cards: Array<Card>;
  title: Nullable<string>;
  type: 'categoryCarousel';
};

export function isCategoryCarousel(data: ComponentData): data is CarouselData {
  return data.type === 'categoryCarousel';
}
