import {
  analyticsMiddleware,
  getSiteInformation,
  getWcsUtagData,
  analyticsContentSpots,
} from '@selfridges-co/frontend-sdk-react-analytics-middleware';

import type {
  AnalyticsEvent,
  AnalyticsEventObject,
  AnalyticsEventReturn,
  EventPropertyFunction,
  WcsData,
} from '@selfridges-co/frontend-sdk-react-analytics-middleware';
import type { FeaturesProps } from '@selfridges-pkg/feature-flag';
import type { AnalyticsProductArray } from './transformers';

const heroCarouselEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'hero_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const urlPath = helpers.getUrlPath(linkElement);
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');

    return {
      promo_creative: [`Slide ${position}|${differentiator}`],
      promo_position: ['hp|Slider|HERO'],
      promo_id: [urlPath],
      promo_name: [`Editorial|${differentiator}`],
    };
  },
};

const categoryCarouselEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const urlPath = helpers.getUrlPath(linkElement);

    return {
      promo_creative: [`Tile${position}|${topic}`],
      promo_position: [`hp|Carousel|${differentiator}`],
      promo_id: [urlPath],
      promo_name: [`Category|${topic}`],
    };
  },
};

const onwardJourneyEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'onward_journey',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
    const urlPath = helpers.getUrlPath(linkElement);
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');

    return {
      promo_creative: [`card${position}|${topic?.toLowerCase()}`],
      promo_position: ['hp|Card_Component|Onward Journey'],
      promo_id: [urlPath],
      promo_name: [`Event|${topic}`],
    };
  },
};

const quickLinksEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'quick_links',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const urlPath = helpers.getUrlPath(linkElement);

    return {
      promo_creative: [`Tile${position}`],
      promo_position: ['hp|Text_link_component'],
      promo_id: [urlPath],
      promo_name: [`Category|${topic}`],
    };
  },
};

const editorialTeaserEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'editorial_teaser',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const urlPath = helpers.getUrlPath(linkElement);
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');

    return {
      promo_creative: [`card${position}|${topic?.toLowerCase()}`],
      promo_position: ['hp|Card_Component|Editorial Teaser'],
      promo_id: [urlPath],
      promo_name: [`Articles|${topic}`],
    };
  },
};

const heroBannerEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'hero_banner',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
    const urlPath = helpers.getUrlPath(linkElement);

    return {
      promo_creative: [`Single_Banner|${topic}`],
      promo_position: ['hp|Banner|Banner'],
      promo_id: [urlPath],
      promo_name: [`Features|${topic}`],
    };
  },
};

const heroOnwardJourneyEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'hero_onward_journey',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const urlPath = helpers.getUrlPath(linkElement);
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');

    return {
      promo_creative: [`Slide ${position}|${differentiator}`],
      promo_position: ['hp|Slider|Onward'],
      promo_id: [urlPath],
      promo_name: [`Editorial|${differentiator}`],
    };
  },
};

const navigationalLinksEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'navigational_links',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const sectionName = helpers.getAnalyticsAttribute(linkElement, 'SectionName');
    const urlPath = helpers.getUrlPath(linkElement);

    return {
      promo_creative: [`Column${position}|${sectionName}`],
      promo_position: [`hp|Text_Link_Component|${topic}`],
      promo_id: [urlPath],
      promo_name: [`Category|${differentiator}`],
    };
  },
};

const promotionalHeaderEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'promotional_header',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const urlPath = helpers.getUrlPath(linkElement);

    return {
      promo_creative: ['hero_banner|sale'],
      promo_position: ['hp|sale_header|hero'],
      promo_id: [`sale|${urlPath}`],
      promo_name: [`sale|${differentiator}`],
    };
  },
};

const productCarouselEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'product_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position');
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const urlPath = helpers.getUrlPath(linkElement);

    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
    const productArray = product?.split('|');
    const { product_brand, product_name } = getProductCarouselData(productArray, position, differentiator);

    return {
      promo_creative: [`Tile${position}|${product_brand}`],
      promo_position: [`hp|Product_Carousel|${differentiator}`],
      promo_id: [urlPath],
      promo_name: [`Product|${product_name}`],
    };
  },
};

const productCarouselEventView: AnalyticsEvent = {
  ...productCarouselEvent,
  onTriggerCustom: ({ linkElement }: AnalyticsEventObject) => {
    const { helpers } = analyticsMiddleware;
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
    const productArray = product?.split('|');

    if (productArray && productArray.length > 0 && productArray[productArray.length - 1] === 'sponsored') {
      const viewBeaconUrl = productArray[9] && JSON.parse(productArray[9]);
      const viewBeaconUrlArr = viewBeaconUrl['viewBeacon'].split('::');
      triggerCriteoEvent(viewBeaconUrlArr);
    }
  },
};

const productCarouselEventClick: AnalyticsEvent = {
  ...productCarouselEvent,
  onTriggerCustom: ({ linkElement }: AnalyticsEventObject) => {
    const { helpers } = analyticsMiddleware;
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
    const productArray = product?.split('|');
    if (productArray && productArray.length > 0 && productArray[productArray.length - 1] === 'sponsored') {
      const clickBeaconUrl = productArray[10] && JSON.parse(productArray[10]);
      const clickBeaconUrlArr = clickBeaconUrl['clickBeacon'].split('::');
      triggerCriteoEvent(clickBeaconUrlArr);
    }
  },
};

const getProductCarouselData = function (productArray, position, differentiator) {
  return {
    product_list_pos: [position],
    product_name: [productArray[0]],
    product_type: [productArray[1]],
    product_id: [productArray[2]],
    product_price: [productArray[3]],
    product_brand: [productArray[4]],
    product_wcid: [productArray[5]],
    product_was_price: [productArray[6]],
    product_was_was_price: [productArray[7]],
    product_badge: [productArray[8]],
    ga_eventlabel: <EventPropertyFunction<unknown>>(
      (prev => [prev, `${differentiator}|${productArray[5]}`].filter(Boolean).join(','))
    ),
  };
};

const productCarouselProductEvent: AnalyticsEvent = {
  app: 'homepage',
  component: 'product_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';
    const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');

    const productArray = product?.split('|') as AnalyticsProductArray;

    return {
      ...getProductCarouselData(productArray, position, differentiator),
      product_list_name: [`hp_product_carousel_${differentiator}`],
    };
  },
};

type GeneralAppInformation = {
  platform: string;
  siteView: string;
  siteVersion: string;
  WCSUtagData: WcsData;
};

type GetProductCarouselRetailMediaProps = GeneralAppInformation & {
  event: 'Impression' | 'PromoView' | 'PromoClick' | 'ImpressionClick';
};

export const getGeneralInformation = function ({
  platform,
  siteView,
  siteVersion,
  WCSUtagData,
}: GeneralAppInformation) {
  const { customer_email, visitor_status, subscription_flag, site_language, site_currency, wcs_user_id, site_region } =
    WCSUtagData;

  return {
    page_breadcrumb: 'home',
    page_category_name: 'homepage',
    page_name: 'homepage',
    customer_email,
    visitor_status,
    subscription_flag,
    site_language,
    site_currency,
    wcs_user_id,
    site_version: siteVersion,
    site_region,
    site_view: siteView,
    platform: platform,
  };
};

export const getProductCarouselRetailMedia = function ({
  platform,
  siteView,
  siteVersion,
  WCSUtagData,
  event,
}: GetProductCarouselRetailMediaProps): AnalyticsEvent {
  return {
    app: 'homepage',
    component: 'product_carousel',
    onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
      const { helpers } = analyticsMiddleware;
      const generalVars = getGeneralInformation({ platform, siteView, siteVersion, WCSUtagData });
      const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';
      const differentiator = helpers.getAnalyticsAttribute(linkElement, 'Differentiator');
      const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
      const urlPath = helpers.getUrlPath(linkElement);
      const productArray = product?.split('|') as AnalyticsProductArray;
      const { product_brand, product_name } = getProductCarouselData(productArray, position, differentiator);

      if (event === 'PromoClick' || event === 'PromoView') {
        const carouselPromoViewClickData = {
          promo_creative: [`Tile${position}|${product_brand}`],
          promo_position: [`hp|Product_Carousel|${differentiator}`],
          promo_id: [urlPath],
          promo_name: [`product|${product_name}`],
          rm_sponsored: ['false'],
        };

        if (productArray && productArray.length > 0 && productArray[productArray.length - 1] === 'sponsored') {
          carouselPromoViewClickData.rm_sponsored = ['true'];
          carouselPromoViewClickData.promo_name = [`retailmedia|${product_name}`];
        }

        return {
          ...generalVars,
          ...carouselPromoViewClickData,
        };
      }
      if (event === 'ImpressionClick') {
        return {
          page_breadcrumb: 'home',
          page_category_id: 'ROOT',
          page_category_name: 'homepage',
          page_name: 'homepage',
          page_type: 'home',
          site_version: siteVersion,
          site_view: siteView,
          product_list_name: [`homepageretailmediacarousel::${differentiator}`],
          rm_sponsored: [`${productArray[productArray.length - 1] === 'sponsored'}`],
          ...WCSUtagData,
          ...getProductCarouselData(productArray, position, differentiator),
        };
      }

      return {
        ...generalVars,
        ...getProductCarouselData(productArray, position, differentiator),
        product_list_name: [`homepageretailmediacarousel::${differentiator}`],
        rm_sponsored: [`${productArray[productArray.length - 1] === 'sponsored'}`],
      };
    },
  };
};

export const getRetailMediaBannerEvent = function ({
  platform,
  siteView,
  siteVersion,
  WCSUtagData,
  event,
}: GetProductCarouselRetailMediaProps): AnalyticsEvent {
  return {
    app: 'homepage',
    component: 'banner',
    onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
      const { helpers } = analyticsMiddleware;
      const generalVars = getGeneralInformation({ platform, siteView, siteVersion, WCSUtagData });
      const urlPath = helpers.getUrlPath(linkElement);
      const topic = helpers.getAnalyticsAttribute(linkElement, 'Topic');
      const sectionName = helpers.getAnalyticsAttribute(linkElement, 'SectionName');

      return {
        ...generalVars,
        promo_creative: [`single_banner|${sectionName}`],
        promo_position: [`hp|retailmedia_banner`],
        promo_id: [urlPath],
        promo_name: [`retailmedia|${topic}`],
        rm_sponsored: ['true'],
      };
    },
    onTriggerCustom: ({ linkElement }: AnalyticsEventObject) => {
      const { helpers } = analyticsMiddleware;

      const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
      const productArray = product?.split('|');

      if (productArray && productArray.length > 0) {
        const beaconUrl = event === 'PromoView' ? productArray[0] : productArray[1];

        triggerCriteoEvent([beaconUrl]);
      }
    },
  };
};

export async function homepageAnalytics(features: FeaturesProps) {
  if (typeof window === 'undefined') return;

  const { IS_SPONSORED_PRODUCTS_FLAG_ENABLED } = features;

  const performanceEntry = performance.getEntriesByType('navigation')[0];

  await analyticsMiddleware.isReady();

  const WCSUtagData = await getWcsUtagData();
  const { siteView, siteVersion } = getSiteInformation();

  window['analyticsContentSpots'] = analyticsContentSpots('homepage');

  const platform = window['FEATURE_SWITCHES'] && window['FEATURE_SWITCHES'].WCSv9Migration ? 'v9' : 'v6';

  const productCarouselProductEventRetailMedia = IS_SPONSORED_PRODUCTS_FLAG_ENABLED
    ? getProductCarouselRetailMedia({ platform, siteView, siteVersion, WCSUtagData, event: 'Impression' })
    : productCarouselProductEvent;

  const productCarouselRetailMediaPromoClick = getProductCarouselRetailMedia({
    platform,
    siteView,
    siteVersion,
    WCSUtagData,
    event: 'PromoClick',
  });

  const productCarouselRetailMediaPromoView = getProductCarouselRetailMedia({
    platform,
    siteView,
    siteVersion,
    WCSUtagData,
    event: 'PromoView',
  });

  const productCarouselRetailMediaImpressionClick = getProductCarouselRetailMedia({
    platform,
    siteView,
    siteVersion,
    WCSUtagData,
    event: 'ImpressionClick',
  });

  const retailMediaBannerEventView = getRetailMediaBannerEvent({
    platform,
    siteView,
    siteVersion,
    WCSUtagData,
    event: 'PromoView',
  });

  const retailMediaBannerEventClick = getRetailMediaBannerEvent({
    platform,
    siteView,
    siteVersion,
    WCSUtagData,
    event: 'PromoClick',
  });

  const componentEvents = [
    heroCarouselEvent,
    categoryCarouselEvent,
    onwardJourneyEvent,
    quickLinksEvent,
    editorialTeaserEvent,
    heroBannerEvent,
    navigationalLinksEvent,
    heroOnwardJourneyEvent,
    promotionalHeaderEvent,
  ];

  analyticsMiddleware.addVisibilityEvents([
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'hp_promotionview',
        ga_event_name: 'hp_promotionview',
        ga_eventcategory: 'hp_promotionview',
        ga_eventaction: 'homepage',
      },
      [...componentEvents, productCarouselEventView],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'hp_product_carousel_impression',
        ga_event_name: 'hp_product_carousel_impression',
        ga_eventcategory: 'enhanced_ecommerce',
        ga_eventaction: 'hp_product_carousel_impression',
      },
      [productCarouselProductEventRetailMedia],
    ),
    ...(features.IS_SPONSORED_PRODUCTS_FLAG_ENABLED
      ? analyticsMiddleware.attachCoreEventProps(
          {
            event_name: 'retailmedia_promotionview',
            ga_event_name: 'retailmedia_promotionview',
            ga_eventcategory: 'retailmedia_promotionview',
            ga_eventaction: 'homepage',
          },
          [productCarouselRetailMediaPromoView, retailMediaBannerEventView],
        )
      : []),
  ]);

  analyticsMiddleware.addClickEvents([
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'hp_promotionclick',
        ga_event_name: 'hp_promotionclick',
        ga_eventcategory: 'hp_promotionclick',
        ga_eventaction: 'homepage',
      },
      [...componentEvents, productCarouselEventClick],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'hp_product_carousel_click',
        ga_event_name: 'hp_product_carousel_click',
        ga_eventcategory: 'enhanced_ecommerce',
        ga_eventaction: 'homepage',
      },
      [productCarouselProductEvent],
    ),
    ...(features.IS_SPONSORED_PRODUCTS_FLAG_ENABLED
      ? analyticsMiddleware.attachCoreEventProps(
          {
            event_name: 'retailmedia_promotionclick',
            ga_event_name: 'retailmedia_promotionclick',
            ga_eventcategory: 'retailmedia_promotionclick',
            ga_eventaction: 'homepage',
          },
          [productCarouselRetailMediaPromoClick, retailMediaBannerEventClick],
        )
      : []),
    ...(features.IS_SPONSORED_PRODUCTS_FLAG_ENABLED
      ? analyticsMiddleware.attachCoreEventProps(
          {
            event_name: 'homepage_product_impression_click',
            event_name_cm: 'homepage_product_impression_click',
            ga_event_name: 'homepage_product_impression_click',
            ga_eventcategory: 'enhanced_ecommerce',
            ga_eventaction: 'homepage_product_impression_click',
          },
          [productCarouselRetailMediaImpressionClick],
        )
      : []),
  ]);

  const analyticsView = {
    pageload_event: 'hp_pageload',
    event_name_cm: 'hp_pageload',
    event_name: 'hp_pageload',
    page_name: 'homepage',
    page_type: 'home',
    page_breadcrumb: 'home',
    page_category_name: 'homepage',
    page_category_id: 'ROOT',
    platform: platform,
    site_view: siteView,
    site_version: siteVersion,
    page_load_time: performanceEntry.duration,
    ...WCSUtagData,
  };

  if (!window.dataLayer) {
    window.dataLayer = {};
  }

  Object.assign(window.dataLayer, analyticsView);

  analyticsMiddleware.triggerPageView(analyticsView);
}

export function clearAnalytics() {
  // run funcs to clear any dangling analytics events related to the app(ie homepage or megamenu)
  analyticsMiddleware.clearEvents('homepage');
}

async function triggerCriteoEvent(beaconUrlArr: Array<string>) {
  for (const beaconUrl of beaconUrlArr) {
    try {
      await fetch(`https:${beaconUrl}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
