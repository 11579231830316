import { Image } from './Image';
import { Link } from './Link';

import { ComponentData } from './index';

export type ThemeTarget = 'light' | 'dark';

export type HeroBannerData = {
  type: 'heroBanner';
  title: string;
  paragraph: Nullable<string>;
  themeText: ThemeTarget;
  blockColour: Nullable<string>;
  desktopImage: Image;
  mobileImage: Image;
  link: Link;
};

export function isHeroBanner(data: ComponentData): data is HeroBannerData {
  return data.type === 'heroBanner';
}
