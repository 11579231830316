import { Link } from './Link';

import { ComponentData } from './index';

export type QuickLinksData = {
  links: Array<Link>;
  title: Nullable<string>;
  type: 'quickLinks';
};

export function isQuickLinks(data: ComponentData): data is QuickLinksData {
  return data.type === 'quickLinks';
}
