/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';

const queryClient = new QueryClient();

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <QueryClientProvider client={queryClient}>
      <App
        features={window['__INITIAL_STATE__'].features}
        tealiumConfig={window['__INITIAL_STATE__'].tealiumConfig}
        data={window['__INITIAL_STATE__'].homepage}
        pageUrl={window['__INITIAL_STATE__'].pageUrl}
        countryLanguage={window['__INITIAL_STATE__'].countryLanguage}
      />
    </QueryClientProvider>
  ),
  renderType: 'hydrate',
  errorBoundary: () => {
    // Customize the root error boundary for your microfrontend here.
    return <div>Error rendering homepage component</div>;
  },
  domElementGetter: () => {
    const domElId = 'mf-app';
    let targetEl = document.getElementById(domElId);

    // fallback to use `homepage` div id if `mf-app` is not found
    if (!targetEl) {
      targetEl = document.getElementById('homepage');
    }

    if (targetEl) return targetEl;

    targetEl = document.createElement('div');
    targetEl.id = domElId;

    document.body.appendChild(targetEl);

    return targetEl;
  },
});

export const { bootstrap, mount, unmount, update } = lifecycles;
