import { Link } from './Link';

import { ComponentData } from './index';

export type Category = {
  type: 'navigationalLinksCategory';
  name: string;
  targetUrl: string;
  links: Array<Link>;
};

export type NavigationalLinksData = {
  categories: Array<Category>;
  title: Nullable<string>;
  type: 'navigationalLinks';
};

export function isNavigationalLinks(data: ComponentData): data is NavigationalLinksData {
  return data.type === 'navigationalLinks';
}
