import urlJoin from 'url-join';

import { CatalogEntry } from '@selfridges-pkg/mashery-api/contracts/CatalogEntry';
import { formatPrice } from '@selfridges-pkg/intl';
import { AvailableCurrency } from '@selfridges-pkg/intl/types';

import { IMAGE_NOT_FOUND } from './constants/imgNames';

import type { HeroCarouselData } from '@selfridges-pkg/content-api/contracts/HeroCarousel';
import type { CarouselData } from '@selfridges-pkg/content-api/contracts/Carousel';
import type { QuickLinksData } from '@selfridges-pkg/content-api/contracts/QuickLinks';
import type { OnwardJourneyData } from '@selfridges-pkg/content-api/contracts/OnwardJourney';
import type { HeroBannerData } from '@selfridges-pkg/content-api/contracts/HeroBanner';
import type { EditorialTeaserData } from '@selfridges-pkg/content-api/contracts/EditorialTeaser';
import type { HeroOnwardJourneyData } from '@selfridges-pkg/content-api/contracts/HeroOnwardJourney';
import type { NavigationalLinksData } from '@selfridges-pkg/content-api/contracts/NavigationalLinks';
import type { ProductCarouselData } from '@selfridges-pkg/content-api/contracts/ProductCarousel';
import type { CtaTarget, CtaType } from '@selfridges-pkg/content-api/contracts/Cta';
import type { PromotionalHeaderData } from '@selfridges-pkg/content-api/contracts/PromotionalHeader';
import type { ProductList } from '@selfridges-pkg/mashery-api/contracts/ProductList';
import type { SponsoredProduct, SponsoredProductProps } from '@selfridges-pkg/criteo-api/contracts/SponsoredProducts';
import type {
  HeroCarouselProps,
  OnwardJourneyProps,
  QuickLinksProps,
  HeroBannerProps,
  EditorialTeaserProps,
  NavigationalLinksProps,
  PromotionalHeaderProps,
} from '@selfridges-pkg/ui-components';
import type { NativeCarouselProps, ProductCarouselProps } from '@selfridges-co/frontend-sdk-react-native-carousel';

function getCtaType(type: CtaType) {
  return type === 'primaryCTA' ? 'primary' : 'secondary';
}

function getTarget(target: CtaTarget) {
  return target === 'sameTab' ? '_self' : '_blank';
}

function transformUrl(url: string, countryLanguage: string) {
  // Return URL straightaway if an absolute URL is given
  if (url.startsWith('https://') || url.startsWith('http://')) return url;

  // Return URL straightaway if URL has already got country language e.g. /GB/en at the beginning
  if (/^\/[a-z]{2}\/[a-z]{2}(\/|$)/i.test(url)) return url;

  // Append country language to relative URL path if country language is missing
  return `/${countryLanguage}${url}`;
}

function transformPrice(price: string, currency: string) {
  return formatPrice(Number(price), currency as AvailableCurrency);
}

function transformImage(imageName: string): { imageUrl: string; hoverImageUrl: string } {
  const mainImage = imageName.replace(new RegExp(`_M$`), '_ALT10');
  const fallbackImage = imageName;

  const scene7UrlRoot = 'https://images.selfridges.com/is/image/selfridges';
  const imageVariants = ['_ALT10', '_ALT01', '_ALT02', '_ALT03'];

  const imageUrl = `${scene7UrlRoot}/${mainImage}?defaultimage=${fallbackImage}`;

  for (let i = 0; i < imageVariants.length; i++) {
    const variant = imageVariants[i];
    const variantRegExp = new RegExp(`${variant}$`);

    if (variantRegExp.test(variant) && i < imageVariants.length) {
      const hoverImageName = `${mainImage.replace(variantRegExp, imageVariants[i + 1])}`;

      return {
        imageUrl,
        hoverImageUrl: `${scene7UrlRoot}/${hoverImageName}?defaultimage=${fallbackImage}`,
      };
    }
  }

  return {
    imageUrl,
    hoverImageUrl: imageUrl,
  };
}

type HeroCarouselPropsToTransform = Partial<HeroCarouselProps> & Pick<HeroCarouselProps, 'slides' | 'slideDuration'>;

export function transformHeroCarouselDataToProps(
  { slides, slideDuration }: HeroCarouselData,
  countryLanguage: string,
): HeroCarouselPropsToTransform {
  return {
    slides: slides.map(({ title, paragraph, primaryLink, secondaryLink, desktopImage, mobileImage }) => ({
      title,
      ...(paragraph && { paragraph }),
      ...(primaryLink && {
        cta: {
          type: 'primary',
          text: primaryLink.text,
          url: transformUrl(primaryLink.url, countryLanguage),
          target: getTarget(primaryLink.target),
        },
      }),
      ...(secondaryLink && {
        link: {
          text: secondaryLink.text,
          url: transformUrl(secondaryLink.url, countryLanguage),
        },
      }),
      desktopImage: {
        scene7Url: desktopImage.scene7Url,
        ...(desktopImage.altText && { altText: desktopImage.altText }),
      },
      ...(mobileImage && {
        mobileImage: {
          scene7Url: mobileImage.scene7Url,
          ...(mobileImage.altText && { altText: mobileImage.altText }),
        },
      }),
    })),
    slideDuration,
  };
}

export function transformHeroOnwardJourneyDataToProps(
  { slides, slideDuration }: HeroOnwardJourneyData,
  countryLanguage: string,
): HeroCarouselPropsToTransform {
  return {
    slides: slides.map(({ tag, title, paragraph, primaryLink, secondaryLink, desktopImage, mobileImage }) => ({
      title,
      ...(paragraph && { paragraph }),
      ...(primaryLink && {
        cta: {
          type: 'primary',
          text: primaryLink.text,
          url: transformUrl(primaryLink.url, countryLanguage),
          target: getTarget(primaryLink.target),
        },
      }),
      ...(secondaryLink && {
        link: {
          text: secondaryLink.text,
          url: transformUrl(secondaryLink.url, countryLanguage),
        },
      }),

      ...(tag && { tag }),
      desktopImage: {
        scene7Url: desktopImage.scene7Url,
        ...(desktopImage.altText && { altText: desktopImage.altText }),
      },
      ...(mobileImage && {
        mobileImage: {
          scene7Url: mobileImage.scene7Url,
          ...(mobileImage.altText && { altText: mobileImage.altText }),
        },
      }),
    })),
    slideDuration,
  };
}

type CarouselPropsToTransform = Partial<NativeCarouselProps> & Pick<NativeCarouselProps, 'title' | 'slides'>;

export function transformCarouselDataToProps(
  { title, cards }: CarouselData,
  countryLanguage: string,
): CarouselPropsToTransform {
  return {
    ...(title && { title }),
    slides: cards.map(({ title: cardTitle, image }) => ({
      title: cardTitle,
      targetUrl: transformUrl(image.targetUrl, countryLanguage),
      image: {
        scene7Url: image.scene7Url,
        ...(image.altText && { altText: image.altText }),
      },
    })),
  };
}

type QuickLinksPropsToTransform = Partial<QuickLinksProps> & Pick<QuickLinksProps, 'title' | 'links'>;

export function transformQuickLinksDataToProps(
  { title, links }: QuickLinksData,
  countryLanguage: string,
): QuickLinksPropsToTransform {
  return {
    ...(title && { title }),
    links: links.map(({ text, url }) => ({
      text,
      url: transformUrl(url, countryLanguage),
    })),
  };
}

type OnwardJourneyPropsToTransform = Partial<OnwardJourneyProps> & Pick<OnwardJourneyProps, 'title' | 'cards'>;

export function transformOnwardJourneyDataToProps(
  { title, cards }: OnwardJourneyData,
  countryLanguage: string,
): OnwardJourneyPropsToTransform {
  return {
    ...(title && { title }),
    cards: cards.map(({ title: cardTitle, image, link }) => ({
      title: cardTitle,
      image: {
        scene7Url: image.scene7Url,
        ...(image.altText && { altText: image.altText }),
      },
      link: {
        text: link.text,
        url: transformUrl(link.url, countryLanguage),
      },
    })),
  };
}

type HeroBannerPropsToTransform = Partial<HeroBannerProps> &
  Pick<HeroBannerProps, 'title' | 'paragraph' | 'mode' | 'blockColour' | 'desktopImage' | 'mobileImage' | 'link'>;

export function transformHeroBannerDataToProps(
  { title, paragraph, themeText, blockColour, desktopImage, mobileImage, link }: HeroBannerData,
  countryLanguage: string,
): HeroBannerPropsToTransform {
  return {
    title,
    ...(paragraph && { paragraph }),
    mode: themeText === 'light' ? 'dark' : 'light',
    ...(blockColour && { blockColour }),
    desktopImage: {
      scene7Url: desktopImage.scene7Url,
      targetUrl: transformUrl(desktopImage.targetUrl, countryLanguage),
      ...(desktopImage.altText && { altText: desktopImage.altText }),
    },
    mobileImage: {
      scene7Url: mobileImage.scene7Url,
      targetUrl: transformUrl(mobileImage.targetUrl, countryLanguage),
      ...(mobileImage.altText && { altText: mobileImage.altText }),
    },
    link: {
      text: link.text,
      url: transformUrl(link.url, countryLanguage),
    },
  };
}

type EditorialTeaserPropsToTransform = Partial<EditorialTeaserProps> & Pick<EditorialTeaserProps, 'title' | 'cards'>;

export function transformEditorialTeaserDataToProps(
  { title, cards }: EditorialTeaserData,
  countryLanguage: string,
): EditorialTeaserPropsToTransform {
  return {
    ...(title && { title }),
    cards: cards.map(({ title: cardTitle, paragraph, image, cta, link }) => ({
      title: cardTitle,
      ...(paragraph && { paragraph }),
      image: {
        scene7Url: image.scene7Url,
        ...(image.altText && { altText: image.altText }),
      },
      cta: {
        type: getCtaType(cta.type),
        text: cta.text,
        url: transformUrl(cta.url, countryLanguage),
        target: getTarget(cta.target),
      },
      ...(link && {
        link: {
          text: link.text,
          url: transformUrl(link.url, countryLanguage),
        },
      }),
    })),
  };
}

type NavigationalLinksPropsToTransform = Partial<NavigationalLinksProps> &
  Pick<NavigationalLinksProps, 'title' | 'categories'>;

export function transformNavigationalLinksDataToProps(
  { title, categories }: NavigationalLinksData,
  countryLanguage: string,
): NavigationalLinksPropsToTransform {
  return {
    ...(title && { title }),
    categories: categories.map(({ name, targetUrl, links }) => ({
      name,
      targetUrl: transformUrl(targetUrl, countryLanguage),
      links: links.map(link => ({
        text: link.text,
        url: transformUrl(link.url, countryLanguage),
      })),
    })),
  };
}

type ProductCarouselPropsToTransform = Partial<ProductCarouselProps> &
  Pick<ProductCarouselProps, 'title' | 'seoTitle' | 'slides'>;

export type AnalyticsProductArray = [
  product_name: string,
  product_type: string,
  product_id: string,
  product_price: string,
  product_brand: string,
  product_wcid: string,
  product_was_price: string,
  product_was_was_price: string,
  product_badge: string,
];

export function filterSponsoredProductsOut(product: CatalogEntry, sponsoredProducts?: Array<SponsoredProduct>) {
  if (!sponsoredProducts?.length) return true;

  const match = sponsoredProducts.find(sponsoredProduct => product.partNumber === sponsoredProduct.ParentSKU);
  if (!match) {
    return true;
  }

  const parsedRenderingAttributes = JSON.parse(match.RenderingAttributes);

  // treat different colors as different products
  return product?.previewAttribute?.toUpperCase() !== parsedRenderingAttributes?.color.toUpperCase();
}

export function transformProductCarouselToProps(
  { title, searchType, searchTerm, linkText, maxItems, seoTitle }: ProductCarouselData,
  productListData: Record<string, ProductList>,
  countryLanguage: string,
  sponsoredProducts: Nullable<SponsoredProductProps[]>,
): ProductCarouselPropsToTransform | null {
  const productList = productListData[`${searchType}${searchTerm}`];
  if (!productList) return null;

  const analyticsProductBadge =
    searchTerm
      .split('?')[1]
      ?.split('&')
      .reduce((acc, curr) => {
        const [key, value] = curr.split('=');
        acc[key] = value;
        return acc;
      }, {})['fh_sort_by'] || 'relevance';

  let linkUrlPath = '';

  if (searchType === 'categoryPath') {
    linkUrlPath = /^\/?cat\//.test(searchTerm) ? urlJoin('/', `${searchTerm}`) : urlJoin('/', 'cat', `/${searchTerm}`); // /cat/{searchTerm}
  } else if (searchType === 'searchTerm') {
    linkUrlPath = urlJoin('/', 'cat', `?freeText=${searchTerm}`); // /cat?freeText={searchTerm}
  }

  const showMoreCTA = Number(productList.recordSetTotal) > maxItems && linkText && linkUrlPath;
  const sponsoredProductsArr = sponsoredProducts?.[0]?.products;

  return {
    ...(title && { title }),
    ...(seoTitle && { seoTitle }),
    slides: productList.catalogEntryNavView
      // sponsored products to be added here as a second argument to filterSponsoredProductsOut function
      .filter(product => filterSponsoredProductsOut(product, sponsoredProductsArr))
      .map(({ brandName, name, imageName, price, seoKey, previewAttribute, partNumber, productType, productId }) => {
        const { imageUrl, hoverImageUrl } = transformImage(imageName || IMAGE_NOT_FOUND);
        const { lowestPrice, currency, lowestWasPrice, lowestWasWasPrice } = price[0];
        const previewColourAttribute = previewAttribute ? `#colour=${previewAttribute}` : '';

        return {
          brandName,
          description: name,
          imageUrl,
          hoverImageUrl,
          targetUrl: transformUrl(`/cat/${seoKey}/${previewColourAttribute}`, countryLanguage),
          lowestPrice: transformPrice(lowestPrice, currency),
          ...(lowestWasPrice && { lowestWasPrice: transformPrice(lowestWasPrice, currency) }),
          ...(lowestWasWasPrice && { lowestWasWasPrice: transformPrice(lowestWasWasPrice, currency) }),
          analyticsDetails: (<AnalyticsProductArray>[
            name,
            productType,
            productId,
            lowestPrice,
            brandName,
            partNumber,
            lowestWasPrice,
            lowestWasWasPrice,
            analyticsProductBadge,
          ]).join('|'),
        };
      }),
    ...(showMoreCTA && {
      linkText,
      linkUrl: transformUrl(linkUrlPath, countryLanguage),
    }),
  };
}

type PromotionalHeaderToTransform = Partial<PromotionalHeaderProps> &
  Pick<PromotionalHeaderProps, 'backgroundColour' | 'image' | 'primaryLink' | 'links'>;

export function transformPromotionalHeaderDataToProps(
  { image, primaryLink, links, backgroundColour }: PromotionalHeaderData,
  countryLanguage: string,
): PromotionalHeaderToTransform {
  return {
    primaryLink: {
      text: primaryLink.text,
      url: transformUrl(primaryLink.url, countryLanguage),
    },
    ...(links && {
      links: links.map(({ text, url }) => ({
        text,
        url: transformUrl(url, countryLanguage),
      })),
    }),
    image: {
      scene7Url: image.scene7Url,
      targetUrl: transformUrl(image.targetUrl, countryLanguage),
      altText: image.altText,
    },
    backgroundColour,
  };
}

export function transformCriteoDataToProps(
  sponsoredProducts: Nullable<Array<SponsoredProductProps>>,
): ProductCarouselProps['slides'] | undefined {
  if (!sponsoredProducts) return;

  const currency = 'GBP';
  const products = sponsoredProducts[0]?.products;
  const OnViewBeacon = sponsoredProducts[0]?.OnViewBeacon;
  const OnClickBeacon = sponsoredProducts[0]?.OnClickBeacon;
  return products?.map((product, i) => {
    const onViewBeaconUrls =
      i === 0
        ? `{"viewBeacon":"${OnViewBeacon}::${product.OnViewBeacon}"}`
        : `{"viewBeacon":"${product.OnViewBeacon}"}`;
    const onClickBeaconUrls =
      i === 0
        ? `{"clickBeacon":"${OnClickBeacon}::${product.OnClickBeacon}"}`
        : `{"clickBeacon":"${product.OnClickBeacon}"}`;
    const renderingAttributes = product?.RenderingAttributes && JSON.parse(product.RenderingAttributes);
    const brandName = renderingAttributes['brand'].toUpperCase();
    const targetUrl = renderingAttributes['targetUrl'];

    return {
      brandName,
      description: product.ProductName,
      sponsored: true,
      lowestPrice: transformPrice(product.Price, currency),
      imageUrl: `${product.Image}`,
      hoverImageUrl: `${renderingAttributes?.['additional_image_link']}`,
      analyticsDetails: [
        product.ProductName,
        undefined,
        product.ProductId,
        product.Price,
        renderingAttributes['brand'].toUpperCase(),
        product.ParentSKU,
        undefined,
        undefined,
        undefined,
        onViewBeaconUrls,
        onClickBeaconUrls,
        'sponsored',
      ].join('|'),
      targetUrl,
      label: {
        text: 'Sponsored',
        textColour: '#212121',
      },
    };
  });
}
