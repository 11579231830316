import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchCriteoList } from '@selfridges-pkg/criteo-api';
import { isMobile } from '@selfridges-pkg/utils';
import { analyticsMiddleware } from '@selfridges-co/frontend-sdk-react-analytics-middleware';

import useGetConsent from './useGetConsent';

import type { UseSponsoredProductsResponse } from '@selfridges-pkg/criteo-api/contracts/SponsoredProducts';

export type UseSponsoredProductsProps = {
  visitor_status?: string;
  wcs_user_id?: string;
};

export default function useSponsoredProducts(
  isFeatureFlagEnabled: boolean,
  isGbEn: boolean,
): UseSponsoredProductsResponse {
  const [sponsoredProductsProps, setSponsoredProductsProps] = useState<UseSponsoredProductsProps>({});
  const [visitorId, setVisitorId] = useState('');
  const { consent } = useGetConsent();

  const isOnMobile = isMobile();
  const criteoPageId = isOnMobile ? 'viewHomeApiMobile' : 'viewHomeApiDesktop';
  const enabledApiCall = Boolean(visitorId && isFeatureFlagEnabled && consent && isGbEn);

  const { data } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ['fetch-criteo-sponsored-products'],
    queryFn: () => fetchCriteoList({ criteoPageId, visitor_id: visitorId, ...sponsoredProductsProps }),
    enabled: enabledApiCall,
  });

  useEffect(() => {
    if (visitorId !== '') return;

    async function checkVisitorData() {
      await analyticsMiddleware.isReady();

      const utag = window.utag;
      setVisitorId(utag.data['cp.utag_main_v_id']);
    }

    checkVisitorData();
  }, []);

  useEffect(() => {
    const getWcsData = () => {
      // take this from session storage because wcsUtagData is a state behind (need refresh to work properly)
      const sessionInfo = JSON.parse(sessionStorage.getItem('AGN_UTAG') || '{}');
      const { visitor_status, wcs_user_id } = sessionInfo;

      setSponsoredProductsProps({ visitor_status, wcs_user_id });
    };

    getWcsData();
  }, []);

  if (data?.status !== 'OK' || !data?.placements?.length) {
    return {
      banner: null,
      sponsoredProducts: null,
    };
  }

  const criteoResponse = {
    banner: [],
    sponsoredProducts: [],
  };

  data.placements.forEach(placementItem => {
    if ('viewHomeApiDesktop-banner' in placementItem) {
      criteoResponse['banner'] = placementItem['viewHomeApiDesktop-banner'];
    } else if ('viewHomeApiDesktop-sponsored' in placementItem) {
      criteoResponse['sponsoredProducts'] = placementItem['viewHomeApiDesktop-sponsored'];
    } else if ('viewHomeApiMobile-banner' in placementItem) {
      criteoResponse['banner'] = placementItem['viewHomeApiMobile-banner'];
    } else if ('viewHomeApiMobile-sponsored' in placementItem) {
      criteoResponse['sponsoredProducts'] = placementItem['viewHomeApiMobile-sponsored'];
    }
  });

  return criteoResponse;
}
