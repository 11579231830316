import { Image } from './Image';
import { Link } from './Link';

import type { ComponentData } from '.';

export type Slide = {
  title: string;
  paragraph: Nullable<string>;
  primaryLink: Nullable<Link>;
  secondaryLink: Nullable<Link>;
  desktopImage: Image;
  mobileImage: Nullable<Image>;
};

export type HeroCarouselData = {
  slides: Array<Slide>;
  slideDuration: number;
  type: 'heroCarousel';
};

export function isHeroCarousel(data: ComponentData): data is HeroCarouselData {
  return data.type === 'heroCarousel';
}
