/* istanbul ignore file */
import React, { useContext, createContext } from 'react';

import { FeaturesProps, Feature } from '.';

const FeatureFlagContext = createContext<FeaturesProps>({} as FeaturesProps);

interface ProviderProps {
  features: FeaturesProps;
  children: React.ReactNode;
}

export function FeatureFlagProvider({ features, children }: ProviderProps) {
  // NOTE: Provide an empty array if value is undefined to distinguish between
  //       this case, and the case where consumer is not being used within provider.
  //
  //       The latter will throw the helpful error seen in the various `use*()` hooks below.
  return <FeatureFlagContext.Provider value={features}>{children}</FeatureFlagContext.Provider>;
}

interface ConsumerProps {
  children: (context: FeaturesProps) => React.ReactNode;
}

export function FeatureFlagConsumer({ children }: ConsumerProps) {
  return <FeatureFlagContext.Consumer>{context => children(context)}</FeatureFlagContext.Consumer>;
}

export function useIsActiveFeatures(flags: Array<Feature>) {
  const context = useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useIsActiveFeatures must be used within a Provider');
  }

  return flags.every(flag => context[flag]);
}
