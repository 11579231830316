type Consent = {
  ct: string | null;
  name: string;
};

export const getDisplayAdsConsent = (list: Consent[]): string => {
  if (!list?.length) return '';

  const [displayAdsConsent] = list.filter(l => l.name === 'display_ads');
  return displayAdsConsent.ct ?? '';
};
