import { Image } from './Image';
import { Cta } from './Cta';
import { Link } from './Link';

import { ComponentData } from './index';

export type Card = {
  title: string;
  paragraph: Nullable<string>;
  image: Image;
  cta: Cta;
  link: Link;
};

export type EditorialTeaserData = {
  cards: Array<Card>;
  title: Nullable<string>;
  type: 'editorialTeaser';
};

export function isEditorialTeaser(data: ComponentData): data is EditorialTeaserData {
  return data.type === 'editorialTeaser';
}
