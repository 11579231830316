/* eslint-disable sonarjs/cognitive-complexity */
import { useState, useEffect, Fragment, AnchorHTMLAttributes } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';

import {
  mainTheme,
  CssBaseline,
  HeadTag,
  HeroCarousel,
  QuickLinks,
  OnwardJourney,
  PromotionalHeader,
  HeroBanner,
  EditorialTeaser,
  NavigationalLinks,
  GridConfigs,
  gridSystem,
  Picture,
} from '@selfridges-pkg/ui-components';
import {
  withVisibilityTracking,
  createTealiumLoaderScript,
} from '@selfridges-co/frontend-sdk-react-analytics-middleware';
import { breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { Grid, Row, Col } from '@selfridges-co/frontend-sdk-react-grid-system';
import NativeCarousel from '@selfridges-co/frontend-sdk-react-native-carousel';
import { logger } from '@selfridges-pkg/logger';
import { isHeroCarousel } from '@selfridges-pkg/content-api/contracts/HeroCarousel';
import { isCategoryCarousel } from '@selfridges-pkg/content-api/contracts/Carousel';
import { isProductCarousel } from '@selfridges-pkg/content-api/contracts/ProductCarousel';
import { isQuickLinks } from '@selfridges-pkg/content-api/contracts/QuickLinks';
import { isOnwardJourney } from '@selfridges-pkg/content-api/contracts/OnwardJourney';
import { isHeroBanner } from '@selfridges-pkg/content-api/contracts/HeroBanner';
import { isEditorialTeaser } from '@selfridges-pkg/content-api/contracts/EditorialTeaser';
import { isHeroOnwardJourney } from '@selfridges-pkg/content-api/contracts/HeroOnwardJourney';
import { isNavigationalLinks } from '@selfridges-pkg/content-api/contracts/NavigationalLinks';
import { isPromotionalHeader } from '@selfridges-pkg/content-api/contracts/PromotionalHeader';
import { Homepage } from '@selfridges-pkg/content-api/contracts/Homepage';
import { ProductList } from '@selfridges-pkg/mashery-api/contracts/ProductList';
import { FeatureFlagProvider } from '@selfridges-pkg/feature-flag/FeatureFlagContext';
import { criteoEventTracker } from '@selfridges-pkg/criteo-api';
import { PictureProps } from '@selfridges-co/frontend-sdk-react-scene7';

import {
  transformCarouselDataToProps,
  transformHeroCarouselDataToProps,
  transformOnwardJourneyDataToProps,
  transformQuickLinksDataToProps,
  transformHeroBannerDataToProps,
  transformEditorialTeaserDataToProps,
  transformHeroOnwardJourneyDataToProps,
  transformNavigationalLinksDataToProps,
  transformProductCarouselToProps,
  transformPromotionalHeaderDataToProps,
  transformCriteoDataToProps,
} from './transformers';
import { homepageAnalytics, clearAnalytics } from './analytics';
import useSponsoredProducts from './hooks/useSponsoredProducts';

import type { Rendering } from '@selfridges-pkg/criteo-api/contracts/SponsoredProducts';
import type { FeaturesProps } from '@selfridges-pkg/feature-flag';
import type { TealiumConfig } from '@selfridges-pkg/app-config';

const { GridConfigsProvider } = gridSystem;

const tealiumLogger = logger.get('homepage', 'tealium');

const Container = styled.div({
  maxWidth: '1920px',
  display: 'grid',
  gridTemplateRows: 'repeat(auto-fill, 1fr)',
  margin: '0 auto',
});

const BannerWrapper = styled.div({
  paddingBottom: '8rem',
});

const VisibilityAnchor = withVisibilityTracking<AnchorHTMLAttributes<HTMLAnchorElement>>(
  styled.a({
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',

    [breakpoints.sm.mediaQuery]: {
      height: '321px',
      maxWidth: '1500px',
      margin: '0 auto',
    },
  }),
);

export interface AppData {
  contentData: Homepage;
  productListData: Record<string, ProductList>;
}

interface AppProps {
  tealiumConfig: TealiumConfig;
  features: FeaturesProps;
  data: AppData;
  pageUrl: string;
  countryLanguage: string;
}

// Fetch function to fetch AEM content
export default function App({ features, tealiumConfig, data, pageUrl, countryLanguage }: AppProps) {
  const [currentTheme] = useState(mainTheme);
  const { contentData, productListData } = data;
  const { IS_SPONSORED_PRODUCTS_FLAG_ENABLED, IS_SPONSORED_BANNER_FLAG_ENABLED } = features;
  const isTestPage = new URL(pageUrl).pathname.includes('headless-home');
  const isGbEn = countryLanguage === 'GB/en';
  const { banner, sponsoredProducts } = useSponsoredProducts(IS_SPONSORED_PRODUCTS_FLAG_ENABLED, isGbEn);

  useEffect(() => {
    homepageAnalytics(features);

    return () => {
      clearAnalytics();
    };
  }, [features]);

  useEffect(() => {
    criteoEventTracker('onLoad', { sponsoredProducts, banner }, IS_SPONSORED_BANNER_FLAG_ENABLED);
  }, [sponsoredProducts, banner, IS_SPONSORED_BANNER_FLAG_ENABLED]);

  const pageTitle = contentData.pageProperties.title;
  const pageDescription = contentData.pageProperties.description;
  const jsonLdOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Selfridges',
    url: 'https://www.selfridges.com',
    sameAs: [
      'https://www.facebook.com/selfridges/',
      'https://twitter.com/Selfridges',
      'https://www.youtube.com/channel/UCvXRE9A1im1wsmfjYzds_9Q',
      'https://www.pinterest.co.uk/selfridges/',
      'https://www.instagram.com/theofficialselfridges/',
      'https://www.linkedin.com/company/selfridges',
    ],
    logo: 'https://www.selfridges.com/GB/en/features/dam/en_gb/content/images/selfridges-logo.svg',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+44 (0)207 160 6222',
        contactType: 'customer service',
      },
    ],
  };

  const pageUrlWithoutTrailingSlash = pageUrl.replace(/\/$/, '');

  const jsonLdWebsite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: pageUrl,
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: `${pageUrlWithoutTrailingSlash}/cat/?freeText={search_term_string}&srch=Y&sug=Y`,
        'query-input': 'required name=search_term_string',
      },
    ],
  };

  const gridConfigs: GridConfigs = {
    fluid: true,
    margin: { xxs: 4, xs: 4, sm: 6, md: 15, lg: 30 },
  };

  const bannerImageProps: Omit<PictureProps, 'src' | 'data-ts-id'> = {
    alt: 'sponsored-banner',
    intrinsicMinWidth: { xxs: 1350, xs: 1350, sm: 1500, md: 1500, lg: 1500 },
    intrinsicMinHeight: { xxs: 900, xs: 900, sm: 321, md: 321, lg: 321 },
    aspectRatio: {
      xxs: [3, 2],
      xs: [3, 2],
      sm: [14, 3],
      md: [14, 3],
      lg: [14, 3],
    },
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <FeatureFlagProvider features={features}>
        <CssBaseline />
        <HeadTag.PageEssentials title={pageTitle} description={pageDescription} countryLanguage={countryLanguage} />
        <HeadTag.SingleSpaEssentials appName="@selfridges/homepage" />
        <HeadTag.Favicon />
        <HeadTag.FontPreload includes={['avalonDemi', 'dinRegular', 'dinMedium']} />
        <HeadTag.OpenGraphMetaTag type="website" title={pageTitle} description={pageDescription} url={pageUrl} />
        <HeadTag.TwitterMetaTag card="summary" title={pageTitle} description={pageDescription} />
        <HeadTag.SmartAppMetaTag />
        <HeadTag.HrefLangs url={pageUrl} />
        <Helmet>
          <link rel="preconnect" href="https://tags.tiqcdn.com" />
          <script type="application/ld+json" className="notranslate">
            {JSON.stringify(jsonLdOrganization)}
          </script>
          <script type="application/ld+json" className="notranslate">
            {JSON.stringify(jsonLdWebsite)}
          </script>
          <link rel="canonical" href={pageUrl} />
          {isTestPage && <meta name="robots" content="noindex" />}
          <script>
            {createTealiumLoaderScript({
              pageType: '',
              loadEventName: 'UTAG_LOADED',
              tealiumConfig,
              logger: tealiumLogger,
            })}
          </script>
        </Helmet>
        <Container data-analytics-app="homepage">
          <GridConfigsProvider configs={gridConfigs}>
            {!contentData.componentList && <div>Error fetching AEM content</div>}
            {contentData.componentList?.map((componentData, index) => {
              // Do not lazy load image for the first 3 components
              const imageLoading = index >= 4 ? 'lazy' : undefined;

              if (isHeroCarousel(componentData)) {
                return (
                  <HeroCarousel
                    key={`homepage-hero-carousel-${index}`}
                    data-ts-id={`homepage-hero-carousel-${index}`}
                    data-analytics-key={`homepage-hero-carousel-${index}`}
                    data-analytics-component="hero_carousel"
                    data-testid="hero-carousel"
                    aria-label="Homepage Hero Carousel"
                    firstSlideTitleH1
                    imageLoading={imageLoading}
                    {...transformHeroCarouselDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isHeroOnwardJourney(componentData)) {
                const showComponent = !componentData.channels || componentData.channels.includes('web');

                return (
                  showComponent && (
                    <HeroCarousel
                      key={`homepage-hero-onward-journey-${index}`}
                      data-ts-id={`homepage-hero-onward-journey-${index}`}
                      data-testid="hero-onward-journey"
                      data-analytics-key={`homepage-hero-onward-journey-${index}`}
                      data-analytics-component="hero_onward_journey"
                      aria-label="Homepage Hero Onward Journey"
                      isHeroOnwardJourney
                      imageLoading={imageLoading}
                      {...transformHeroOnwardJourneyDataToProps(componentData, countryLanguage)}
                    />
                  )
                );
              }

              if (isPromotionalHeader(componentData)) {
                return (
                  <PromotionalHeader
                    key={`homepage-header-${index}`}
                    data-ts-id={`homepage-promotional-header-${index}`}
                    data-analytics-key={`homepage-promotional-header-${index}`}
                    data-analytics-component="promotional_header"
                    data-testid="promotional-header"
                    imageLoading={imageLoading}
                    {...transformPromotionalHeaderDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isCategoryCarousel(componentData)) {
                return (
                  <NativeCarousel
                    key={`homepage-carousel-${index}`}
                    data-analytics-key={`homepage-carousel-${index}`}
                    data-analytics-component="carousel"
                    data-ts-id={`homepage-carousel-${index}`}
                    data-testid="native-carousel"
                    aria-label="Homepage Category Carousel"
                    imageLoading={imageLoading}
                    {...transformCarouselDataToProps(componentData, countryLanguage)}
                    gridConfigs={gridConfigs}
                  />
                );
              }

              if (isQuickLinks(componentData)) {
                return (
                  <QuickLinks
                    key={`homepage-quick-links-${index}`}
                    data-analytics-key={`homepage-quick-links-${index}`}
                    data-analytics-component="quick_links"
                    data-ts-id={`homepage-quick-links-${index}`}
                    data-testid="quick-links"
                    {...transformQuickLinksDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isProductCarousel(componentData)) {
                try {
                  const productCarouselProps = transformProductCarouselToProps(
                    componentData,
                    productListData,
                    countryLanguage,
                    sponsoredProducts,
                  );
                  const criteoCarouselProps = transformCriteoDataToProps(sponsoredProducts);

                  let productCarouselData = productCarouselProps;
                  if (isGbEn && criteoCarouselProps && criteoCarouselProps.length > 0 && productCarouselProps) {
                    productCarouselData = {
                      ...productCarouselProps,
                      slides: [...criteoCarouselProps, ...productCarouselProps.slides],
                    };
                  }
                  const bannerInfo = banner?.[0]?.rendering as Rendering;
                  const displaySponsoredBanner = Boolean(isGbEn && IS_SPONSORED_BANNER_FLAG_ENABLED && bannerInfo);

                  const bannerBeacons = banner?.length
                    ? [banner[0]?.OnViewBeacon, banner[0]?.OnClickBeacon].join('|')
                    : '';

                  return (
                    <ErrorBoundary fallback={<></>}>
                      <Fragment key={`homepage-product-carousel-${index}`}>
                        {productCarouselData && (
                          <NativeCarousel
                            data-analytics-key={`homepage-product-carousel-${index}`}
                            data-analytics-component="product_carousel"
                            data-ts-id={`homepage-product-carousel-${index}`}
                            data-testid="native-product-carousel"
                            aria-label="Homepage Product Carousel"
                            isProductCarousel
                            imageLoading={imageLoading}
                            {...productCarouselData}
                            gridConfigs={gridConfigs}
                          />
                        )}
                        {displaySponsoredBanner && (
                          <BannerWrapper
                            data-analytics-component="banner"
                            data-analytics-key={`homepage-banner-${index}`}
                            data-analytics-details={bannerBeacons}
                            data-analytics-topic={bannerInfo.brand_name}
                            data-analytics-section-name={bannerInfo.promo_name}
                            data-testid="sponsored-banner-wrapper"
                          >
                            <Grid {...gridConfigs}>
                              <Row>
                                <Col xxs={4} sm={6} md={12}>
                                  <VisibilityAnchor
                                    href={bannerInfo.redirect_url}
                                    target={bannerInfo.redirect_target}
                                    aria-label="sponsored-banner"
                                    data-testid="sponsored-banner"
                                  >
                                    <Picture
                                      {...bannerImageProps}
                                      data-ts-id="sponsored-banner-img"
                                      src={{
                                        xxs: `https:${bannerInfo.mobile_background_image}`,
                                        xs: `https:${bannerInfo.mobile_background_image}`,
                                        sm: `https:${bannerInfo.desktop_background_image}`,
                                        md: `https:${bannerInfo.desktop_background_image}`,
                                        lg: `https:${bannerInfo.desktop_background_image}`,
                                      }}
                                      loading={imageLoading}
                                    />
                                  </VisibilityAnchor>
                                </Col>
                              </Row>
                            </Grid>
                          </BannerWrapper>
                        )}
                      </Fragment>
                    </ErrorBoundary>
                  );
                } catch (e) {
                  return;
                }
              }

              if (isOnwardJourney(componentData)) {
                return (
                  <OnwardJourney
                    key={`homepage-onward-journey-${index}`}
                    data-analytics-key={`homepage-onward-journey-${index}`}
                    data-analytics-component="onward_journey"
                    data-ts-id={`homepage-onward-journey-${index}`}
                    data-testid="onward-journey"
                    imageLoading={imageLoading}
                    {...transformOnwardJourneyDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isHeroBanner(componentData)) {
                return (
                  <HeroBanner
                    key={`homepage-hero-banner-${index}`}
                    data-analytics-component="hero_banner"
                    data-ts-id={`homepage-hero-banner-${index}`}
                    data-testid="hero-banner"
                    imageLoading={imageLoading}
                    {...transformHeroBannerDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isEditorialTeaser(componentData)) {
                return (
                  <EditorialTeaser
                    key={`homepage-editorial-teaser-${index}`}
                    data-analytics-component="editorial_teaser"
                    data-ts-id={`homepage-editorial-teaser-${index}`}
                    data-testid="editorial-teaser"
                    imageLoading={imageLoading}
                    {...transformEditorialTeaserDataToProps(componentData, countryLanguage)}
                  />
                );
              }

              if (isNavigationalLinks(componentData)) {
                return (
                  <NavigationalLinks
                    key={`homepage-navigational-links-${index}`}
                    data-analytics-component="navigational_links"
                    data-ts-id={`homepage-navigational-links-${index}`}
                    data-testid="navigational-links"
                    {...transformNavigationalLinksDataToProps(componentData, countryLanguage)}
                  />
                );
              }
            })}
          </GridConfigsProvider>
        </Container>
      </FeatureFlagProvider>
    </ThemeProvider>
  );
}
