import { useEffect, useState } from 'react';

import { getGdprInformation } from '@selfridges-co/frontend-sdk-react-analytics-middleware';

import { getDisplayAdsConsent } from '../utils/gdpr';
import { NEWSLETTER_EVENT } from '../constants/gdpr';

export default function useGetConsent() {
  const [consent, setConsent] = useState<boolean>(false);

  const getConsent = async () => {
    const data = await getGdprInformation();
    if (typeof data === 'number') {
      if (data === 1) {
        setConsent(true);
      }
    } else if (getDisplayAdsConsent(data) === '1') {
      setConsent(true);
    }
  };

  useEffect(() => {
    getConsent();
  }, []);

  useEffect(() => {
    window.addEventListener(NEWSLETTER_EVENT, getConsent);

    return () => {
      window.removeEventListener(NEWSLETTER_EVENT, getConsent);
    };
  }, []);

  return { consent };
}
